import React, { useState } from 'react';
import './Chat.css'; // Importamos los estilos
import axios from 'axios';

const Chat = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);  // Estado para controlar expansión


  // Función para reproducir un sonido
  const playSound = (soundFile) => {
    const audio = new Audio(`/sounds/${soundFile}`);
    audio.play();
  };

  const handleSendMessage = async (message) => {
    if (!message) return;

    // Agregar mensaje del usuario al historial
    setMessages([...messages, { role: 'user', content: message }]);
    setMessage('');
    
    // Reproducir el sonido cuando el usuario envía un mensaje
    playSound('send-sound.mp3');

    try {
        // Enviar solicitud al backend de Flask
        const response = await axios.post('/api/chat', {
            message: message,
        });

        const botMessage = response.data.response;

        // Agregar respuesta del bot al historial
        setMessages([...messages, { role: 'user', content: message }, { role: 'bot', content: botMessage }]);

        // Reproducir el sonido cuando el bot responde
        playSound('receive-sound.mp3');
    } catch (error) {
        console.error('Error al comunicarse con el servidor:', error);
    }
  };

  const toggleChat = () => {
    setIsExpanded(prevState => !prevState);  // Cambiar estado de expansión
  };

  // Función para agregar mensajes predeterminados (botones)
  const handleButtonClick = (buttonContent) => {
    handleSendMessage(buttonContent);
  };

  return (
    <div className="chat-container">
      <div className="chat-header" onClick={toggleChat} style={{ cursor: 'pointer' }}>
        {isExpanded ? 'Chat' : 'Chat'}
      </div>
      {isExpanded && (
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index}>
              <div className={`message ${msg.role}`}>
              <strong>{msg.role === 'user' ? 'Tú: ' : 'Bot: '}</strong>{msg.content}
             </div>
             {/* Si es un mensaje del bot que contiene botones, mostrar los botones */}
             {msg.isButtons && (
                <div className="message-buttons">
                  <button onClick={() => handleButtonClick('Realizar un análisis comparativo entre los ingresos del período actual con el período anterior')}>Realizar un análisis comparativo entre los ingresos del período actual con el período anterior</button>
                  <button onClick={() => handleButtonClick('¿Qué puedes hacer por mí?')}>¿Qué puedes hacer por mí?</button>
                  <button onClick={() => handleButtonClick('¿Cuáles son tus funcionalidades?')}>¿Cuáles son tus funcionalidades?</button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {isExpanded && (
        <div className="chat-input-container">
          <button onClick={() =>handleSendMessage('Me regalas un análisis financiero básico con las ventas y los costos?')}>Me regalas un análisis financiero básico con las ventas y los costos?</button>
        {/* 
         <button onClick={() => handleSendMessage('Realizar un análisis de crecimiento de los costos período a período')}>Realizar un análisis de crecimiento de los costos período a período</button>
          <button onClick={() => handleSendMessage('Realizar un análisis entre la variación de las ventas y la variación de los costos período a período ')}>Realizar un análisis entre la variación de las ventas y la variación de los costos período a período </button>
        */}
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Escribe tu mensaje..."
          />
          <button onClick={() => handleSendMessage(message)}>Enviar</button>

        </div>
      )}
    </div>
  );
};

export default Chat;
